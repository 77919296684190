import React from "react";
import { Modal } from "antd";
import MajorMinorForm from "./MajorMinorForm";
import { EmployeeMajorMinor } from "types/EmployeeMajorMinor";

type MajorMinorCardModalProps = {
  modalState: boolean;
  employeeId: number;
  onSubmit: (newMajorMinor: EmployeeMajorMinor) => void;
  closeModal: () => void;
};

const MajorMinorCardModal = (props: MajorMinorCardModalProps) => {
  return (
    <Modal
      title={"About Me"}
      visible={props.modalState}
      onCancel={props.closeModal}
      footer={null}
    >
      <MajorMinorForm
        employeeId={props.employeeId}
        onSubmit={props.onSubmit}
        onCancel={props.closeModal}
      />
    </Modal>
  );
};

export default MajorMinorCardModal;
