import React, { useEffect, useState } from "react";
import { Button, Form, Radio, Select } from "antd";
import displayErrorNotification from "utils/displayErrorNotification";
import ButtonRow from "components/common/ButtonRow";
import { updateEmployeeMajorMinor } from "api/employeeMajorMinorApi";
import { EmployeeMajorMinor } from "types/EmployeeMajorMinor";
import { EmployeeMajorMinorRequest } from "types/EmployeeMajorMinorRequest";
import {
  convertLandingsPageCapability,
  convertLandingsPageIndustry
} from "utils/convert";
import { getIndustrySectors } from "api/industrySectorApi";
import { getLandingPageIndustry } from "api/industriesApi";
import { getLandingPageCapability } from "api/capabilitiesApi";
import { getEmployeePractices } from "api/employeePracticeApi";
import { showSector } from "utils/skillDetails";
import { getEmployee } from "api/employeeApi";
import { useUser } from "auth/UserHooks";

type MajorMinorFormTypes = {
  employeeId: number;
  onSubmit: (newMajorMinor: EmployeeMajorMinor) => void;
  onCancel: () => void;
  form: any;
};

type Dropdown = {
  id: number;
  name: string;
};

const MajorMinorForm = ({
  employeeId,
  form,
  onSubmit,
  onCancel
}: MajorMinorFormTypes) => {
  const userId = useUser().id;
  const [industryDropdown, setIndustryDropdown] = useState<Dropdown[]>([]);
  const [capabilityDropdown, setCapabilityDropdown] = useState<Dropdown[]>([]);
  const [isMajor, setIsMajor] = useState<boolean>(false);
  const [isCapability, setIsCapability] = useState(true);
  const [employeeLevel, setEmployeeLevel] = useState(0);
  const { getFieldDecorator } = form;
  const initialValues = {
    major: isMajor.toString(),
    majorMinorId: "",
    capabilityBool: true
  };

  const getEmployeeLevel = async (employeeId: number) => {
    const employee = await getEmployee(employeeId);
    setEmployeeLevel(employee.level);
  };

  useEffect(() => {
    getEmployeeLevel(employeeId);
    Promise.all([
      getLandingPageIndustry(),
      getIndustrySectors(),
      getLandingPageCapability(),
      getEmployeePractices()
    ])
      .then(response => {
        let convertedIndustry = convertLandingsPageIndustry(response[0]);
        let convertedCapability = convertLandingsPageCapability(response[2]);
        let industryDropdown: Dropdown[] = [];
        let capabilityDropdown: Dropdown[] = [];

        convertedCapability.forEach(c => {
          if (!c.inactive) {
            capabilityDropdown.push({
              id: c.id,
              name:
                showSector(response[3], c.employeePracticeId) + " | " + c.name
            });
          }
        });
        capabilityDropdown.sort((a, b) => a.name.localeCompare(b.name));
        setCapabilityDropdown(capabilityDropdown);

        convertedIndustry.forEach(i => {
          if (!i.inactive) {
            industryDropdown.push({
              id: i.id,
              name: showSector(response[1], i.industrySectorId) + " | " + i.name
            });
          }
        });
        industryDropdown.sort((a, b) => a.name.localeCompare(b.name));
        setIndustryDropdown(industryDropdown);
      })
      .catch(displayErrorNotification);
  }, []);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    form.validateFields((err: any, values: any) => {
      if (err) return;
      const request: EmployeeMajorMinorRequest = {
        employeeId: employeeId,
        majorMinorId: values.majorMinorId,
        major: isMajor,
        capabilityBool: isCapability,
        modifiedById: userId,
        createdById: userId
      };

      updateEmployeeMajorMinor(employeeId, request)
        .then(onSubmit)
        .catch(displayErrorNotification);
    });
  };

  return (
    <>
      <h2>Major and Minor</h2>
      <Form
        layout="vertical"
        style={{ maxWidth: "40em" }}
        onSubmit={handleSubmit}
      >
        {employeeLevel > 4 ? (
          <Form.Item label="Type" required>
            {getFieldDecorator("isCapability", {
              initialValue: initialValues.capabilityBool,
              rules: [{ required: true, message: "Type is a required field" }]
            })(
              <Radio.Group>
                <Radio
                  key={0}
                  value={true}
                  onChange={() => setIsCapability(true)}
                  style={{
                    height: "30px",
                    lineHeight: "30px",
                    marginLeft: "0px"
                  }}
                >
                  Capability
                </Radio>
                <Radio
                  key={1}
                  value={false}
                  onChange={() => setIsCapability(false)}
                  style={{
                    height: "30px",
                    lineHeight: "30px",
                    marginLeft: "0px"
                  }}
                >
                  Industry
                </Radio>
              </Radio.Group>
            )}
          </Form.Item>
        ) : null}
        {isCapability ? (
          <Form.Item label="Capability" required>
            {getFieldDecorator("majorMinorId", {
              initialValue: initialValues.majorMinorId,
              rules: [
                {
                  required: true,
                  message: "Capability/Specialization is a required field"
                }
              ]
            })(
              <Select placeholder="Select a Capability/Specialization">
                {capabilityDropdown.map(d => (
                  <Select.Option key={d.id} value={d.id}>
                    {d.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        ) : (
          <Form.Item label="Industry" required>
            {getFieldDecorator("majorMinorId", {
              initialValue: initialValues.majorMinorId,
              rules: [
                { required: true, message: "Industry is a required field" }
              ]
            })(
              <Select placeholder="Select an Industry">
                {industryDropdown.map(d => (
                  <Select.Option key={d.id} value={d.id}>
                    {d.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        )}
        <Form.Item label="Major/Minor" required>
          {getFieldDecorator("major", {
            initialValue: initialValues.major,
            rules: [{ required: true, message: "Major or Minor is required" }]
          })(
            <Select placeholder="Minor" onChange={() => setIsMajor(!isMajor)}>
              <Select.Option key="major" value="true">
                Major
              </Select.Option>
              <Select.Option key="minor" value="false">
                Minor
              </Select.Option>
            </Select>
          )}
        </Form.Item>
        <ButtonRow style={{ marginTop: "1em" }}>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
          <Button onClick={onCancel}>Cancel</Button>
        </ButtonRow>
      </Form>
    </>
  );
};

export default Form.create<MajorMinorFormTypes>({ name: "major_minor_form" })(
  MajorMinorForm
);
